.App {
  font-family: Roboto !important;
}

p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #686868;
}

.container--full {
  position: relative;
	background-color: #ffffff;
}

.container {
  position: relative;
	max-width: 995px;
  margin: auto;
  padding: 0rem 1rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.errores {
  color: red;
  line-height: 1.5;
  padding-left: 0;
  font-size: 12px;
  list-style: none;
  margin-bottom: 0;
}
/* Modificado Ant */
.ant-form-item {
  margin-bottom: 8px !important;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px !important;
}

.ant-btn-primary {
  font-size: 12px !important;
}
.ant-form-item-label > label {
  font-size: 12px !important;
}
.ant-input {
  font-size: 11.5px !important;
}
.ant-select-dropdown-menu-item {
  font-size: 12px !important;
}
.ant-steps-icon {
  font-size: 20px !important;
}
.ant-steps {
  justify-content: space-between;
}
.ant-steps-item {
  flex: auto !important;
  flex-grow: 1;
  flex-shrink: 1;
}
.ant-select-selection-selected-value {
  font-size: 12px;
}
.ant-checkbox-wrapper {
  font-size: 12px !important;
}
.ant-collapse-header {
  font-weight: 500;
  font-size: 15px !important;
  line-height: 19px;
  color: #686868;
}
.col__exito {
  font-size: 12px !important;
}